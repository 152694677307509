import { template as template_47424eab81b246fa8e712b77a60f59df } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_47424eab81b246fa8e712b77a60f59df(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
