import { template as template_b06126bfff4c4f848af08886ac6b0d03 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b06126bfff4c4f848af08886ac6b0d03(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
