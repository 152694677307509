import { template as template_975dc60e920a4f30a6d9f7921df92d5b } from "@ember/template-compiler";
const FKText = template_975dc60e920a4f30a6d9f7921df92d5b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
